<template>
  <div>
    <staff-categories-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <staff-categories-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import StaffCategoriesFilter from '@/views/Nsi/StaffCategories/StaffCategoriesFilter';
import StaffCategoriesTable from '@/views/Nsi/StaffCategories/StaffCategoriesTable';

export default {
  name: 'StaffCategories',
  components: { StaffCategoriesTable, StaffCategoriesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
