<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
  >
  <div slot="title">
      Категории персонала
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
          <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="staffCategoryForm" :model="staffCategory" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="staffCategory.name"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="Код:">
        <el-input v-model="staffCategory.code"></el-input>
      </el-form-item>
      <el-form-item prop="actual" label="Признак актуальности:">
        <el-checkbox v-model="staffCategory.actual"/>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input v-model="staffCategory.mRid"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import staffCategoriesApi from '@/api/nsi/staffCategories';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'StaffCategoryModal',
  props: ['value', 'staffCategory'],
  mixins: [validationRules],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          {
            required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
          },
          {
            whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
          },
          {
            max: 150, message: 'До 150 символов', trigger: 'blur'
          }
        ],
        code: [
          {
            required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
          },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          {
            max: 2, message: 'До 2 символов', trigger: 'blur'
          }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addStaffCategory(staffCategory) {
      await staffCategoriesApi.addStaffCategory(staffCategory);
      this.$emit('on-create');
    },
    async updateStaffCategory(staffCategory) {
      await staffCategoriesApi.updateStaffCategory(staffCategory);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.staffCategoryForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.staffCategory.id ? this.updateStaffCategory : this.addStaffCategory;
      this.loading = true;
      await request(this.staffCategory);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
